import React from "react";

import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "gatsby";

const useStyles = makeStyles(() => ({
  grid: {
    width: "100%",
  },
  link: {
    textDecoration: "none",
  },
  item: { flexBasis: "50%" },
}));

type PostProps = { id: string; title: string; path: string; foreword: string };

type PostGridProps = {
  posts: PostProps[];
};

export function PostGrid({ posts }: PostGridProps) {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.grid}>
      {posts.map((post) => (
        <Grid item className={classes.item}>
          <PostGridItem post={post} />
        </Grid>
      ))}
    </Grid>
  );
}

function PostGridItem({ post }: { post: PostProps }) {
  const classes = useStyles();

  return (
    <Link to={post.path} className={classes.link}>
      <Card>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {post.title}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {post.foreword}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
