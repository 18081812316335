import React from "react";

import { Container, Grid } from "@material-ui/core";

import Helmet from "react-helmet";
import { graphql } from "gatsby";

import { TopBanner } from "../banner";
import { PostGrid } from "../post-grid";
import { CallToAction } from "../landing";

function toPostProps(node: QueryResult["allSitePage"]["edges"][0]["node"]) {
  return {
    id: node.id,
    title: node.context.title,
    path: node.path,
    foreword: node.context.foreword,
  };
}

function IndexPage({ data }: { data: QueryResult }) {
  const MyHelmet = Helmet as any;
  return (
    <main>
      <MyHelmet>
        <title>no shortcut</title>
      </MyHelmet>
      <TopBanner />
      <Container maxWidth="md">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <CallToAction avatarUrl={data.file.publicURL} />
          </Grid>
          <Grid item>
            <PostGrid
              posts={data.allSitePage.edges.map((edge) =>
                toPostProps(edge.node)
              )}
            />
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}

export default IndexPage;

export const avatarQuery = graphql`
  query avatarFile {
    file(relativePath: { eq: "avatar.jpg" }) {
      publicURL
    }
    allSitePage(filter: { context: { type: { eq: "post" } } }) {
      edges {
        node {
          path
          context {
            title
            foreword
          }
          id
        }
      }
    }
  }
`;

type QueryResult = {
  file: { publicURL: string };
  allSitePage: {
    edges: {
      node: {
        path: string;
        id: string;
        context: {
          title: string;
          foreword: string;
        };
      };
    }[];
  };
};
