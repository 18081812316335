import React from "react";

import {
  Avatar,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";

const rotation = 2;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.secondary.dark,
    transform: `rotate(-${rotation}deg)`,
  },
  textInPaper: { transform: `rotate(${rotation}deg)` },
  emphasis: {
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.light,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    float: "left",
    top: -theme.spacing(4),
    left: -theme.spacing(5),
    border: `${theme.spacing(1)}px solid ${theme.palette.primary.light}`,
    zIndex: 1,
  },
}));

export function CallToAction({ avatarUrl }: { avatarUrl: string }) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div style={{ marginTop: theme.spacing(7) }}>
      <Avatar alt="Lioda" src={avatarUrl} className={classes.avatar} />
      <Paper variant="elevation" elevation={15} className={classes.paper}>
        <div className={classes.textInPaper}>
          <Typography>
            <p>
              <div>
                I am a Senior Software Engineer. I write some interactive
                articles and explorable explanations, to experiment with ideas,
                train ourselves and expose{" "}
                <span className={classes.emphasis}>
                  fundamentals, concepts and mindset
                </span>
                .
              </div>
              <div>
                Because without practicing, learning is tough. Without concrete
                cases, theory is hard.
              </div>
            </p>
            <p>
              <div>
                Software Engineering is a vast field, with a lot to know:
                algorithms, data structures, design patterns, architecture, TDD,
                Object Oriented Design and Principles, Network, Functional
                Programming, Databases, DDD, eXtreme Programming...
              </div>
              <div>
                And Software Engineering is not only technical: most important
                skills are the craftsman
                <span className={classes.emphasis}>mindset and attitude</span>,
                toward code and people.
              </div>
            </p>
            <p>
              <div>
                <span className={classes.emphasis}>There is no shortcut:</span>{" "}
                all this knowledge is linked, each one built on top or solving a
                problem of another one.
              </div>
            </p>
          </Typography>
        </div>
      </Paper>
    </div>
  );
}
